import React from "react"
import Layout from "../layouts/Layout"
import {TweenMax, Expo} from "gsap/TweenMax";
import cn from "classnames"
import css from "./training.module.css"
import { graphql, StaticQuery } from "gatsby"
import { ImageShowAnimated } from "../shared/components/ImageShowAnimated"
import SEO from "../core/seo"
import { TrainingStatsGrid } from "../layouts/training/TrainingStatsGrid"
/*import '../../node_modules/font-awesome/css/font-awesome.min.css';*/
/*import '../shared/css/typography.css';*/
/*import '../shared/css/styles.css';*/



export const thumbLogoTraining = graphql`
  fragment thumbLogoTraining on File {
    childImageSharp {
      fluid(maxHeight: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`


export const imgTraining = graphql`
  fragment imgTraining on File {
    childImageSharp {
      fluid(maxHeight: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`


export const imgTrainingBig = graphql`
  fragment imgTrainingBig on File {
    childImageSharp {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
class Training extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            bigPic: file(relativePath: { eq: "training/img__sky.png" }) { ...imgTrainingBig },
            angularFundamentals: file(relativePath: { eq: "training/angular-fundamentals-banner.png" }) { ...imgTrainingBig },
            angular2: file(relativePath: { eq: "loghi/angular2.png" }) { ...thumbLogoTraining },
            react: file(relativePath: { eq: "loghi/react.png" }) { ...thumbLogoTraining },
            ngrx: file(relativePath: { eq: "loghi/ngrx.png" }) { ...thumbLogoTraining },
            redux: file(relativePath: { eq: "loghi/redux.png" }) { ...thumbLogoTraining },
            training_configurator: file(relativePath: { eq: "misc/training-configurator.png" }) { ...imgTraining },
            livecoding: file(relativePath: { eq: "misc/live-coding.png" }) { ...imgTraining },
            area_edu: file(relativePath: { eq: "misc/areaedu2.png" }) { ...imgTraining },
            evaluation: file(relativePath: { eq: "misc/evaluation.png" }) { ...imgTraining },
          }
        `}
        render={data => <TrainingContent {...this.props}  data={data} /> }
     />
    )
  }
}



class TrainingContent extends React.Component {

  componentDidMount() {
    /*const bgImg = new Image();
    bgImg.onload = () => this.aniMask();
    bgImg.src = this.props.data.bigPic.childImageSharp.fluid.src;*/
    // bgImg.src = '../static/home/training-sky.jpg';
    this.showFooterButton();
  }

  showFooterButton() {
    TweenMax.from(".button_footer_left", 0.7, {
      bottom: -60,
      delay: 2,
      ease: Expo.easeInOut
    })
  }
/*
  aniMask() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    const size = w > h ? w : h;
    TweenMax.to(".clip-circle", 1.00, {
      delay: 0,
      webkitClipPath: `circle(${size/2 + 50} at 50% 50%)`,
      clipPath: `circle(${size/2 + 50} at 50% 50%)`,
      ease: Expo.easeInOut
    });
  }*/
  render() {

/*
    const styles = {
      // background: `url("${getStaticPath()}static/home/training-sky.jpg") no-repeat center center`,
      background: `url("${this.props.data.bigPic.childImageSharp.fluid.src}") no-repeat center center`,
      backgroundSize: 'cover'
    }
*/

    return (
      <Layout {...this.props}>
        <SEO title="Training"/>
        <div style={{overflowX: 'hidden'}}>
          <ImageShowAnimated
            className={cn('page_wrapper_header_img', 'clip-circle')}
            image={this.props.data.bigPic.childImageSharp.fluid.src}

          />
          {/*<div
            className="page_wrapper_header_img clip-circle "
            style={styles}
          >
            <div className="page_title_xxl_wrapper">
              <div className="title_xxl text-shadow-xxl">
                TRAINING
              </div>
            </div>
          </div>*/}

        </div>

        <a href="https://formazione.fabiobiondi.io/" target="_blank" rel="noopener noreferrer">
          <div className="button_footer_left bg-angular">
            <i className="fa fa-external-link-square" aria-hidden="true"></i> COURSE CATALOG
          </div>
        </a>

        <div className="container-xxl">

          <br/>
          <div className="grid_3x3">

            <div className="item">
              <h1 className="title_text_xl">
                TRAINING
              </h1>
              <h2 className={cn('title_text_m', css.page_logo_second_line)}>
                <strong>Javascript</strong>, <strong>Angular</strong>, <strong>React</strong>
              </h2>
            </div>

            <div className={cn('item', 'hide-on-md')} style={{textAlign: 'right'}}>
              <img alt="logo" src={this.props.data.angular2.childImageSharp.fluid.src} />
              <img alt="logo" src={this.props.data.react.childImageSharp.fluid.src} />
              <br/>
            </div>
          </div>

            <div className="paragraph">
              <br/>

              I'm <strong>Google Developer Expert in web technologies and Angular</strong> but I love <strong>React</strong> ecosystem as well.
              So all my courses focus on Javascript ES6+, Typescript, Angular, React, RxJS and state managers such as Redux or NGRX.
              <br/>
              Visit my <a href="https://formazione.fabiobiondi.io/" target="_blank" rel="noopener noreferrer" className="color-react">training web site</a> to see course details, programs and reviews (italian only).
              <br/>

              {/*<div>
                <div className="separator"></div>
              </div>*/}
            </div>


{/*
          <TrainingStatsGrid />
*/}

          <br/>

          {/*<div className="separator"></div>*/}


          <div className="grid_3x3">


            <div className="item text-center">
              {/*<div className="pad">
                <a href="http://training.fabiobiondi.io" target="_blank" rel="noopener noreferrer">
                  <div className="button_outline  noSelect border-js">
                    <i className="fa fa-code"></i> TRAINING <br/>WEBSITE
                  </div>
                </a>
              </div>*/}
            </div>

            <div className="item text-center">
              {/*<div className="pad">
                <a href="http://www.fabiobiondi.io/tools/training-course-configurator/" target="_blank" rel="noopener noreferrer">
                  <div className="button_outline  noSelect border-angular">
                    <i className="fa fa-check"></i> COURSE <br/>CONFIGURATOR
                  </div>
                </a>
              </div>*/}
              <div className="pad">
                <a href="https://formazione.fabiobiondi.io/" target="_blank" rel="noopener noreferrer">
                  <div className="button_outline  noSelect" style={{width: 250, height: 60}}>
                    <i className="fa fa-code"></i> COURSE CATALOG
                    <br/>
                    <div style={{fontSize: '1rem'}}>(Italian Only)</div>
                  </div>
                </a>
              </div>
            </div>

            <div className="item text-center">
              {/*<div className="pad">
                <a href="https://www.facebook.com/pg/biondifabio/reviews/" target="_blank" rel="noopener noreferrer">
                  <div className="button_outline  noSelect border-react">
                    <i className="fa fa-facebook"></i> FACEBOOK <br/>PAGE
                  </div>
                </a>
              </div>*/}
            </div>

          </div>


         {/* <h2 className={cn('title_text_xl', 'text-center')}>
            HOW TO START
          </h2>*/}
{/*
          <div className="paragraph text-center">
            Usually, my courses are organized in the following way:
          </div>*/}

          <br/>

          <br/>
          <br/>

          <div className="separator"></div>

          <div className="grid_3x3">

            <div className="item">
              <div className="title_text_l text-bold">
                ANGULAR FUNDAMENTALS
              </div>
              <div className="title_text_m">
                VIDEO CORSO IN ITALIANO
              </div>
              <div className={cn('paragraph', css.page_logo_second_line)} style={{paddingRight: 10}}>
                In February 2019 I have released an Angular video course in italian.
                About 200 videos about <span className="color-js">Javascript ES6</span>, <span className="color-react">Typescript</span> & <span className="color-angular text-bold">Angular</span> (version 7). <br />
                <br/> Visit <a href="https://www.angularfundamentals.com" target="_blank" rel="noopener noreferrer">angularfundamentals.com</a> to get more info about it. Subscribe the newsletter to get free content and receive  updates
              </div>
              <br/>
              <br/>
            </div>

            <div className={cn('item')} style={{textAlign: 'right'}}>
              <a href="https://www.angularfundamentals.com" target="_blank" rel="noopener noreferrer">
                <img alt="logo" src={this.props.data.angularFundamentals.childImageSharp.fluid.src} width="100%"/>
              </a>
              <br/>
            </div>
          </div>

        </div>
        <br/>
        <br/>


        <br/>
        <br/>
        <br/>

      </Layout>
    )
  }
}

export default Training
